@mixin responsive-mobile {
  @media (max-width: $size-breakpoint) {
    @content;
  }
}

@mixin responsive-desktop {
  @media (min-width: $size-breakpoint) {
    @content;
  }
}
