// General styles

@mixin typo-regular($size) {
  font-family: "Helvetica LT Std";
  font-weight: 500;
  line-height: 1.2;
  font-size: $size;
  letter-spacing: -0.041em;
}

@mixin typo-bold($size) {
  font-family: "Helvetica LT Std";
  font-weight: bold;
  line-height: 1.2;
  font-size: $size;
  letter-spacing: -0.07em;
}

// Type System

@mixin typo-body {
  @include responsive-mobile {
    @include typo-regular(18px);
  }

  @include responsive-desktop {
    @include typo-regular(22px);
  }
}

@mixin typo-body-strong {
  @include responsive-mobile {
    @include typo-regular(18px);
  }

  @include responsive-desktop {
    @include typo-bold(22px);
  }
}

@mixin typo-extra-fat {
  @include responsive-mobile {
    @include typo-bold(30px);
  }

  @include responsive-desktop {
    @include typo-bold(60px);
  }
}

@mixin typo-fat {
  @include responsive-mobile {
    @include typo-bold(20px);
  }

  @include responsive-desktop {
    @include typo-bold(40px);
  }
}

@mixin typo-discreet {
  @include responsive-mobile {
    @include typo-regular(11px);
  }

  @include responsive-desktop {
    @include typo-regular(16px);
  }
}

@mixin typo-menu {
  @include responsive-mobile {
    @include typo-regular(14px);
  }

  @include responsive-desktop {
    @include typo-regular(20px);
  }
}

@mixin typo-button {
  @include responsive-mobile {
    @include typo-bold(14px);
  }

  @include responsive-desktop {
    @include typo-bold(22px);
  }
}
