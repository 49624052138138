@font-face {
  font-family: "Helvetica LT Std";
  src: url("HelveticaLTStd-Bold.eot");
  src: url("HelveticaLTStd-Bold.eot?#iefix") format("embedded-opentype"),
    url("HelveticaLTStd-Bold.woff2") format("woff2"),
    url("HelveticaLTStd-Bold.woff") format("woff"),
    url("HelveticaLTStd-Bold.ttf") format("truetype"),
    url("HelveticaLTStd-Bold.svg#HelveticaLTStd-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica LT Std";
  src: url("HelveticaLTStd-Roman.eot");
  src: url("HelveticaLTStd-Roman.eot?#iefix") format("embedded-opentype"),
    url("HelveticaLTStd-Roman.woff2") format("woff2"),
    url("HelveticaLTStd-Roman.woff") format("woff"),
    url("HelveticaLTStd-Roman.ttf") format("truetype"),
    url("HelveticaLTStd-Roman.svg#HelveticaLTStd-Roman") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica LT Std";
  src: url("HelveticaLTStd-BoldObl.eot");
  src: url("HelveticaLTStd-BoldObl.eot?#iefix") format("embedded-opentype"),
    url("HelveticaLTStd-BoldObl.woff2") format("woff2"),
    url("HelveticaLTStd-BoldObl.woff") format("woff"),
    url("HelveticaLTStd-BoldObl.ttf") format("truetype"),
    url("HelveticaLTStd-BoldObl.svg#HelveticaLTStd-BoldObl") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

.typo--body {
  @include typo-body;
}

.typo--extra-fat {
  @include typo-extra-fat;
}

.typo--fat {
  @include typo-fat;
}

.typo--discreet {
  @include typo-extra-fat;
}
