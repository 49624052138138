@font-face {
    font-family: "Baikal-light";
    src: url("./fonts/Baikal-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Baikal";
    src: url("./fonts/Baikal-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
  }

.popin{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: none;

    &--open{
        display: flex;
    }

    .popin__content{
        background-color: #fff;
        padding: 20px;
        padding-bottom: 50px;
        width: 80%;
        max-width: 600px;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 200px;

        .btn-close
        {
            cursor: pointer;
            align-self: flex-end;
        }
    }
}

.popin-texte{
    font-size: 30px;
    font-family: "Baikal-light";
    max-width: 500px;

    .red{
        color: #E60000;
    }
}

.popin-link{
    font-size: 18px;
    text-decoration: none;
    color: black;
    position: relative;
    font-family: "Baikal";

    &::after{
        content: "";
        position: absolute;
        width: 110%;
        height: 2px;
        bottom: -2px;
        left: -5%;
        background-color: black;
    }
}