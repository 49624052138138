// Responsive

$size-breakpoint: 768px;
$size-max-container-width: 1400px;

// Space
$size-space: 12px;

// Menu
$size-menu-content-height-desktop: 108px;
$size-menu-height-desktop: $size-menu-content-height-desktop + 4 * 1 *
  $size-space;
$size-menu-content-height-mobile: 54px;
$size-menu-height-mobile: $size-menu-content-height-mobile + 2 * 2 * $size-space;
