@mixin spacing-intersperce-vertical($between: $size-space, $ends: $between) {
  padding-top: $ends / 2;
  padding-bottom: $ends / 2;
  & > * {
    padding-top: $between / 2;
    padding-bottom: $between / 2;
    &:first-child {
      padding-top: $ends / 2;
    }
    &:last-child {
      padding-bottom: $ends / 2;
    }
  }
}

@mixin spacing-intersperce-horizontal($between: $size-space, $ends: $between) {
  padding-left: $ends / 2;
  padding-right: $ends / 2;
  & > * {
    padding-left: $between / 2;
    padding-right: $between / 2;
    &:first-child {
      padding-left: $ends / 2;
    }
    &:last-child {
      padding-right: $ends / 2;
    }
  }
}
