@mixin reset-link {
  text-decoration: none;
  color: inherit;
}

@mixin reset-button {
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
  display: inline-block;
  cursor: pointer;
}

@mixin reset-list {
  list-style-type: none;
}
